<template>
  <!-- VRM操作切り替えボタン -->
  <div
    class="ho-toggle-control-button"
    :class="{ '-on': props.control }"
    @click="toggleControl"
  />
</template>

<script lang="ts" setup>
type Props = {
  control: boolean
}
const props = defineProps<Props>()

type Emits = {
  (e: 'click'): void
}
const emit = defineEmits<Emits>()

const toggleControl = () => {
  emit('click')
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

@mixin image($url) {
  background: url($url);
  background-repeat: no-repeat;
  background-size: contain;
}

.ho-toggle-control-button {
  cursor: pointer;
  position: relative;
  @include image('/images/profile/button-avatar-look.webp');

  &::after {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.1s ease;
    width: 100%;
    @include image('/images/profile/button-avatar-look-on.webp');
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &.-on {
    @include image('/images/profile/button-prev.webp');
    background-repeat: no-repeat;
    background-size: contain;

    &::after {
      @include image('/images/profile/button-prev-on.webp');
    }
  }
}
</style>
