<template>
  <div class="ho-mypage-emote-radio">
    <div v-for="option in _options" :key="option.value" class="radio">
      <HaBaseInput
        :id="option.value"
        class="input"
        type="radio"
        :name="props.name"
        :model-value="option.value"
        :checked="option.checked"
        :disabled="option.disabled"
        required
        @change="onChange($event)"
      />
      <label :for="option.value" class="label">
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script lang="ts" setup>
type Radio = {
  label: string
  value: string
  checked?: boolean
  disabled?: boolean
}

type Props = {
  name: string
  options: Radio[]
}
const props = defineProps<Props>()

const _options = computed(() => props.options)

type Emits = {
  (e: 'change', value: string): void
}
const emit = defineEmits<Emits>()
const onChange = (e: Event) => {
  if (e.target instanceof HTMLInputElement && !e.target.disabled) {
    emit('change', e.target.value)
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-mypage-emote-radio {
  display: flex;
  gap: v.space(2);
  left: v.space(4);
  position: relative;
  width: 100%;

  @include m.sp() {
    font-size: 12px;
  }

  .radio {
    color: v.$white;
    flex: 1;
    max-width: 90px;
    width: 100%;

    > .input {
      display: none;

      &:checked {
        + .label {
          background-color: v.$gray-3;
          border-top: 4px solid v.$orange;
          font-weight: 900;

          @include m.sp() {
            font-weight: 700;
          }
        }
      }

      &:disabled {
        + .label {
          background-color: v.$button-disabled-color;
          border-color: v.$white;
          color: v.$button-disabled-text-color;
          cursor: not-allowed;
        }
      }
    }

    > .label {
      align-items: center;
      background-color: v.$gray-4;
      border-radius: v.space(1) v.space(1) 0 0;
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      padding: v.space(1), v.space(4);
      text-align: center;
      user-select: none;
    }
  }
}
</style>
