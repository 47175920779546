<i18n lang="yaml">
ja:
  error: |
    3Dモデルの表示に失敗しました。
    お使いの環境では3Dモデルを表示できない可能性があります。
en:
  error: |
    If the 3D model takes too long to display,
    please reload and try again.
</i18n>
<template>
  <div class="hm-vrm-viewer" @drop="onDrop">
    <canvas :id="viewerId" ref="viewerRef" class="canvas" tabindex="-1" />

    <template v-if="state.isError">
      <div class="background" />
      <div class="error-dialog" @click="reload">
        <IconReload class="reload" />
        <div class="message">
          {{ i18n.t('error') }}
        </div>
      </div>
    </template>
    <div id="scripts" />
  </div>
</template>

<script lang="ts" setup>
// composables
import { useVrmViewer } from '@/composables/useVrmViewer'

// assets
import IconReload from '@/assets/icons/icon-reload.svg'

const {
  state,
  startSkyway,
  start,
  setIsScriptLoaded,
  onloadedAdd,
  resizeCanvas,
  changeCameraHeight,
  unLoadVrmViewer,
  checkLoading,
} = useVrmViewer()

const props = withDefaults(
  defineProps<{
    disableIdleMotion?: boolean
    resizeTime?: number // 何秒かけてリサイズするか
    defaultCameraHeightPc?: number
  }>(),
  {
    disableIdleMotion: true,
    resizeTime: 500,
    defaultCameraHeightPc: 0.5,
  }
)

const emit = defineEmits<{
  (e: 'drop', event: FileList): void
}>()
const onDrop = (e: DragEvent) => {
  // dispatchEvent経由なら読み込まない
  if (!e.isTrusted) return
  if (!e.dataTransfer) return
  emit('drop', e.dataTransfer.files)
}

const i18n = useI18n()

const viewerRef = ref<Element | null>(null)
const viewerId = computed(() => state.viewerId)

const reload = () => {
  location.reload()
}

const resizeWindow = () => {
  if (!viewerRef.value || process.server) return

  // リサイズが完了するまでローディングを表示する
  checkLoading(50)

  resizeCanvas(viewerRef.value.clientWidth, viewerRef.value.clientHeight)
}

/** resizeTimeかけてリサイズする */
const resizeTransition = () => {
  const resize = setInterval(() => {
    resizeWindow()
  }, 18)

  setTimeout(() => clearInterval(resize), props.resizeTime)
}

// TODO: defineExposeを使わない方法を検討
defineExpose({
  resizeTransition,
})

const addMouseListener = () => {
  const canvas = document.getElementById('canvas')
  let mouseY = 0

  /** マウス操作で発火させるイベント */
  const moveCanvas = (e: MouseEvent) => {
    changeCameraHeight(`${(e.pageY - mouseY) / 200}`)
    mouseY = e.pageY
  }

  /** マウス操作のリスナーを追加 */
  const addMouseMoveListener = (e: MouseEvent) => {
    e.preventDefault()

    // 右クリック以外では操作しない
    if (e.button !== 2) {
      return
    }
    // クリック位置の取得
    mouseY = e.pageY
    canvas?.addEventListener('mousemove', moveCanvas)
  }
  /** マウス操作のリスナーを削除 */
  const removeMouseMoveListener = () => {
    canvas?.removeEventListener('mousemove', moveCanvas)
  }

  canvas?.addEventListener('mousedown', addMouseMoveListener)
  canvas?.addEventListener('mouseup', removeMouseMoveListener)
}

watch([() => state.isLoaded], () => {
  if (state.isLoaded) {
    // start直後にキャンバスのサイズを初期化
    resizeWindow()
    // dragでカメラのheightを変更させる
    // NOTE: heliodor側にカメラ操作が実装されたら削除する
    addMouseListener()
    window.addEventListener('resize', resizeWindow)
    window.addEventListener('orientationchange', resizeTransition)
  }
})

onMounted(async () => {
  start() // vrmのローディングチェック開始
  // scriptsタグを取得できるまで数秒待つ
  await new Promise((resolve) => setTimeout(() => resolve(true), 300))

  const heliodorScriptDOM = document.getElementById('heliodor')
  if (heliodorScriptDOM) {
    // 既にheliodorが読み込まれている場合はスクリプトのロードを終了
    startSkyway()
  }

  // template内に直接scriptタグを書けないので、mount後にappendする
  const scripts = document.body

  if (!scripts) {
    console.error('Failed to get scripts')
    return
  }

  // heliodor_frontのpostRunが呼ばれないことがあるのでこちらに定義
  const isLoadedWasm = ref(false) // postRunでtrueになる
  window.Module = {
    postRun: () => {
      isLoadedWasm.value = true
    },
  }

  const heliodorPreScript = document.createElement('script')
  const heliodorScript = document.createElement('script')
  const heliodorFrontScript = document.createElement('script')
  const heliodorPostScript = document.createElement('script')

  heliodorPreScript.setAttribute(
    'src',
    // `https://heliodor.vketcdn.com/heliodor/common/latest/v1/data/heliodor_pre.js`
    '/data/heliodor_pre.js'
  )
  heliodorScript.setAttribute('id', 'heliodor')
  heliodorScript.setAttribute(
    'src',
    // `https://heliodor.vketcdn.com/heliodor/common/latest/v1/data/heliodor.js`
    '/data/heliodor.js'
  )
  heliodorFrontScript.setAttribute('src', `/data/heliodor_front_viewer.js`)
  heliodorPostScript.setAttribute(
    'src',
    // `https://heliodor.vketcdn.com/heliodor/common/latest/v1/data/heliodor_post.js`
    '/data/heliodor_post.js'
  )

  // TODO:もっとスマートな実装方法考える
  // note: 読み込み順が下記になるように修正
  //       外部パッケージ, skyway, heliodor_pre, heliodor_room, heliodor, heliodor_post, heliodor_front
  scripts.appendChild(heliodorScript)
  heliodorScript.onload = () => {
    scripts.appendChild(heliodorPreScript)
  }
  heliodorPreScript.onload = () => {
    scripts.appendChild(heliodorFrontScript)
  }
  heliodorFrontScript.onload = () => {
    scripts.appendChild(heliodorPostScript)
  }
  heliodorPostScript.onload = () => {
    onloadedAdd(() => {
      setIsScriptLoaded() // scriptのロード終了
    })
    const skywayInterval = setInterval(() => {
      if (isLoadedWasm.value) {
        startSkyway()
        clearInterval(skywayInterval)
      }
    }, 500)
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeWindow)
  unLoadVrmViewer()
})
</script>

<style lang="scss">
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.hm-vrm-viewer {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  > .canvas {
    cursor: move;
    height: 100%;
    position: absolute;
    touch-action: none;
    width: 100%;
  }

  > .cover {
    cursor: default;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &.-drop {
      pointer-events: none;
    }
  }

  > .interaction {
    bottom: 0;
    height: 45vw;
    left: 0;
    margin: auto;
    max-height: 90%;
    min-height: 70%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 400px;

    @include m.tb {
      width: 30%;
    }

    @include m.sp {
      width: 40%;
    }
  }

  > .other {
    height: 0;
    width: 0;
  }

  > .scripts {
    height: 0;
    width: 0;
  }

  > .background {
    background: v.$black;
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    opacity: 0.8;
    position: absolute;
    right: 0;
    top: 100px;
    width: 400px;

    @include m.sp {
      top: v.space(25);
      width: 100%;
    }
  }
}

.error-dialog {
  align-items: center;
  background: v.$gray-1;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  left: 50%;
  max-width: 90%;
  padding: v.space(4);
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
  z-index: v.$zindex-dialog - 1;

  @include m.tb {
    max-width: 50%;
    top: 20%;
  }

  @include m.sp {
    top: 30%;
  }

  > .reload {
    display: block;
    height: 32px;
    width: 32px;
  }

  > .message {
    color: v.$black;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    margin-left: v.space(4);
    white-space: pre-line;
    width: 296px;
  }
}
</style>
